import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/components/StickyHeader.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/styles/globals.scss");
